'use client';
import {LinkButton} from '#/features/components/LinkButton';

type Props = {
  label: string;
  href?: string;
};

export const ShowOnMapButton = (props: Props) => {
  const {label, href} = props;

  return (
    <div className="overflow-hidden rounded-2xl border border-midground2 bg-map">
      <div className="flex items-center justify-center bg-primary/50 p-10">
        <LinkButton
          label={label}
          url={href ?? '#'}
          pillClassName="bg-background max-w-96 w-full"
          iconLeftName="Maps"
          iconRightName="ArrowRight"
          color="primary"
          hoverColor="secondary"
          className="flex justify-center md:w-full"
        />
      </div>
    </div>
  );
};
