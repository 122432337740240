'use client';
import {type Sight} from '#/cms/schemas/sight.schema';
import {Categories} from '#/features/components/Categories';
import SightResult from '#/features/components/SightResult';
import {useUiStrings} from '#/features/store/uiStrings';
import urls from '#/urls';
import {useSightCategoryIds} from '#/utils/categoryHooks';
import {HomePreviewSection} from './HomePreviewSection';

type Props = {
  sights: Sight[];
};

export const SightsPreview = (props: Props) => {
  const {sights} = props;
  const uiStrings = useUiStrings();
  const sightCategoryIds = useSightCategoryIds(sights);

  return (
    <HomePreviewSection
      title={uiStrings.sights.previewTitle}
      showAllButtonLabel={uiStrings.sights.previewShowAllButtonLabel}
      showOnMapButtonLabel={uiStrings.sights.previewShowOnMapButtonLabel}
      urlPathBase={urls.sights}
      Pills={
        <Categories
          categoryIds={sightCategoryIds}
          categoryCollectionName="sightCategories"
          urlPathBase={urls.sights}
          ariaLabel="sightCategories"
        />
      }
    >
      {sights.map(sight => (
        <SightResult key={`sight-result-${sight.id}`} sight={sight} />
      ))}
    </HomePreviewSection>
  );
};
