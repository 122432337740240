import {type AmenityCategory} from '#/cms/schemas/amenity-category.schema';
import {type Category} from '#/cms/schemas/category.schema';
import {type EventCategory} from '#/cms/schemas/event-category.schema';
import {type Event} from '#/cms/schemas/event.schema';
import {type Sight} from '#/cms/schemas/sight.schema';
import {type CategoryFilterLogic, type Collection} from '#/cms/types';
import {useMemo} from 'react';

/**
 * Returns an array of sight category ids from the given sights.
 */
export const useSightCategoryIds = (sights: Sight[]) => {
  const sightCategoryIds = sights
    .map(sight => sight.sightCategories?.map(({id}) => id))
    .flat()
    .filter((id): id is string => !!id);
  const uniqueSightCategoryIds = [...new Set(sightCategoryIds)];
  return uniqueSightCategoryIds;
};

/**
 * Returns an array of event category ids from the given events.
 */
export const useEventCategoryIds = (events: Event[]) => {
  const eventCategoryIds = events
    .map(event => event.eventCategories?.map(({id}) => id))
    .flat()
    .filter((id): id is string => !!id);
  const uniqueEventCategoryIds = [...new Set(eventCategoryIds)];
  return uniqueEventCategoryIds;
};

const defaultCategoryFilterLogic: Record<
  Extract<Collection, 'eventCategories' | 'amenityCategories' | 'sightCategories'>,
  CategoryFilterLogic
> = {
  eventCategories: 'or',
  amenityCategories: 'and',
  sightCategories: 'or',
};

/**
 * The logic used to filter feature collections by categories
 */
export const useCategoryFilterLogic = (): Record<
  Extract<Collection, 'eventCategories' | 'amenityCategories' | 'sightCategories'>,
  CategoryFilterLogic
> => {
  // TODO: These need to come from the CMS settings once we add support for it.
  //   https://gitlab.calvium.com/calvium/pep-place-experience-platform/pep-web-app/-/issues/212
  return defaultCategoryFilterLogic;
};

/**
 * Given an array of categories, an array of active category ids; this hook returns an array of categoryIds for a particular feature collection that can not be selected due filtering and the filteredFeatures.
 * ie, any categories that do not appear on the filteredFeatures due to category filtering will be in the returned array.
 * If the categoryFilterLogic is not 'or' then no categories will be disabled (all categories will be enabled because only 'and' logic should disable categories).
 */
export const useDisabledCategoryIdsForFeatureCollection = <
  _Category extends Category,
  _Feature extends {
    [categoryCollectionId in Collection]?: {id: string}[];
  },
>(params: {
  categories: _Category[];
  activeCategoryIds: string[];
  filteredFeatures: _Feature[];
  categoryCollectionId: Extract<Collection, 'eventCategories' | 'amenityCategories' | 'sightCategories'>;
  categoryFilterLogic: CategoryFilterLogic;
}) => {
  const {categories, activeCategoryIds, filteredFeatures, categoryCollectionId, categoryFilterLogic} = params;
  const andLogicResult = useMemo(
    () =>
      categories
        .filter(category => {
          if (activeCategoryIds.includes(category.id)) {
            return false;
          }
          return !filteredFeatures.some(feature => feature[categoryCollectionId]?.some(({id}) => id === category.id));
        })
        .map(eventCategory => eventCategory.id),
    [categories, activeCategoryIds, filteredFeatures, categoryCollectionId]
  );
  return categoryFilterLogic === 'and' ? andLogicResult : undefined;
};

/**
 * Returns the event category ids that can not be selected due to 'and' logic filtering
 */
export const useDisabledEventCategoryIdsForEvents = (params: {
  eventCategories: EventCategory[];
  activeEventCategoryIds: string[];
  filteredEvents: Event[];
  eventCategoryFilterLogic: CategoryFilterLogic;
}) => {
  const {eventCategories, activeEventCategoryIds, filteredEvents, eventCategoryFilterLogic} = params;
  const disabledEventCategoryIdsForEvents = useDisabledCategoryIdsForFeatureCollection<EventCategory, Event>({
    categories: eventCategories,
    activeCategoryIds: activeEventCategoryIds,
    filteredFeatures: filteredEvents,
    categoryCollectionId: 'eventCategories',
    categoryFilterLogic: eventCategoryFilterLogic,
  });
  return disabledEventCategoryIdsForEvents;
};

/**
 * Returns the amenity category ids that can not be selected due to 'and' logic filtering
 */
export const useDisabledAmenityCategoryIdsForEvents = (params: {
  amenityCategories: AmenityCategory[];
  activeAmenityCategoryIds: string[];
  filteredEvents: Event[];
  amenityCategoryFilterLogic: CategoryFilterLogic;
}) => {
  const {amenityCategories, activeAmenityCategoryIds, filteredEvents, amenityCategoryFilterLogic} = params;
  const disabledAmenityCategoryIdsForEvents = useDisabledCategoryIdsForFeatureCollection<AmenityCategory, Event>({
    categories: amenityCategories,
    activeCategoryIds: activeAmenityCategoryIds,
    filteredFeatures: filteredEvents,
    categoryCollectionId: 'amenityCategories',
    categoryFilterLogic: amenityCategoryFilterLogic,
  });
  return disabledAmenityCategoryIdsForEvents;
};
