'use client';
import {type Event} from '#/cms/schemas/event.schema';
import {EventResult} from '#/features/components/EventResult';
import {useUiStrings} from '#/features/store/uiStrings';
import urls from '#/urls';
import {HomePreviewSection} from './HomePreviewSection';
import {type EventCategory} from '#/cms/schemas/event-category.schema';
import {TimeFramePills} from './TimeFramePills';

type Props = {
  events: Event[];
  eventCategories?: EventCategory[];
};

export const EventsPreview = (props: Props) => {
  const {events} = props;
  const uiStrings = useUiStrings();

  return (
    <HomePreviewSection
      title={uiStrings.events.previewTitle}
      showAllButtonLabel={uiStrings.events.previewShowAllButtonLabel}
      showOnMapButtonLabel={uiStrings.events.previewShowOnMapButtonLabel}
      urlPathBase={urls.events}
      Pills={<TimeFramePills urlPathBase={urls.events} ariaLabel={uiStrings.events.timeFrame} />}
    >
      {events.map(event => (
        <EventResult key={`event-result-${event.id}`} event={event} />
      ))}
    </HomePreviewSection>
  );
};
