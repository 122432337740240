'use client';

import {type Link} from '#/cms/schemas/link.schema';
import classNames from 'classnames';
import Image from 'next/image';
import NextLink from 'next/link';
import {useBaseUrl, useFileUrl} from '../store/clientEnvStore';
import {useUiStrings} from '../store/uiStrings';
import {Heading3, SmallText} from './Text';

type Props = {
  link: Link;
  className?: string;
};

export const LinkCard = (props: Props) => {
  const {link, className} = props;
  const {title, description, image, websiteLabel, websiteUrl} = link;
  const imageUrl = useFileUrl(image);
  const uiStrings = useUiStrings();
  const baseUrl = useBaseUrl();
  const internalLink = websiteUrl?.startsWith(baseUrl) ? websiteUrl.replace(baseUrl, '') : null;

  if (!websiteUrl) return null;

  return (
    <NextLink
      href={internalLink ?? websiteUrl}
      target={!internalLink ? '_blank' : undefined}
      className={classNames(className, 'group')}
    >
      <div className="grid h-full grid-cols-3 overflow-hidden rounded-2xl border-2 border-midground2 bg-background group-hover:border-primary group-hover:bg-midground1">
        <div className="relative overflow-hidden">
          <Image src={imageUrl} alt={title ?? ''} className="object-cover" fill />
        </div>
        <div className="col-span-2 p-4">
          {!!title && <Heading3>{title}</Heading3>}
          <SmallText className="line-clamp-2">{description}</SmallText>
          <SmallText className="font-semibold text-primary">{websiteLabel ?? uiStrings.links.defaultLabel}</SmallText>
        </div>
      </div>
    </NextLink>
  );
};
