'use client';
import Image from 'next/image';

import classNames from 'classnames';
import {type ReactNode} from 'react';
import {SmallText} from './Text';
import {DateBadge} from './DateBadge';

type Props = {
  className?: string;
  imageUrl: string;
  imageAlt: string;
  icon?: ReactNode;
  type?: string;
  title: string;
  isoDate?: string;
  endIsoDate?: string;
  subtitle?: string;
};

export const DashboardResult = (props: Props) => {
  const {className, imageUrl, icon, type, title, isoDate, endIsoDate, imageAlt, subtitle} = props;
  return (
    <div className={classNames('group flex flex-col gap-y-2', className)}>
      <div className="relative h-44 overflow-hidden rounded-2xl border border-midground2 mobile:w-full">
        {imageUrl ? (
          <Image className="object-cover" src={imageUrl} alt={imageAlt} fill />
        ) : (
          <div className="absolute inset-0 bg-midground2"></div>
        )}
      </div>
      <div className="flex">
        {!!icon && <div className="rounded bg-primary p-4">{icon}</div>}
        <div className="flex gap-3">
          {!!isoDate && <DateBadge startDate={isoDate} endDate={endIsoDate} />}
          <div className="flex max-w-[14rem] flex-col">
            {!!type && <div className="text-primary">{type}</div>}
            <p className="line-clamp-1 text-h3 font-bold leading-tight text-foreground">{title}</p>
            {!!subtitle && <SmallText className="text-clip">{subtitle}</SmallText>}
          </div>
        </div>
      </div>
    </div>
  );
};
