import {type ColorName} from '#/cms/schemas/theme-colors.schema';
import classNames from 'classnames';

type Props = {
  children: React.ReactNode;
  borderColor?: ColorName;
  fill?: boolean;
  className?: string;
};

export const Pill = (props: Props) => {
  const {children, fill, className, borderColor = 'primary'} = props;

  const baseClassNames = ['flex', 'gap-2', 'whitespace-nowrap', 'rounded-full', 'px-3', 'py-1', 'text-small'];
  const fillClassName = classNames(...baseClassNames, 'bg-primary', 'text-background', className);
  const nonFillClassName = classNames(
    ...baseClassNames,
    'border-2',
    'text-foreground',
    `border-${borderColor}`,
    className
  );

  return <div className={fill ? fillClassName : nonFillClassName}>{children}</div>;
};
