'use client';

import {type Event} from '#/cms/schemas/event.schema';
import {useMemo} from 'react';
import {DashboardResult} from './DashboardResult';
import {usePlaceStore} from '#/features/store/placeStore';
import {useFileUrl} from '#/features/store/clientEnvStore';
import Link from 'next/link';
import {useUiStrings} from '../store/uiStrings';

import {getEventUrl} from '#/utils/seo/urls';

type Props = {
  event: Event;
};

export const EventResult = (props: Props) => {
  const {event} = props;
  const {placeMap} = usePlaceStore();
  const subtitle = useMemo(
    () =>
      event.placeIds
        ?.map(placeId => placeMap.get(placeId))
        .filter(Boolean)
        .map(place => place!.title)
        .join(', '),
    [placeMap, event.placeIds]
  );
  const uiStrings = useUiStrings();
  const imageUrl = useFileUrl(event.imageSources?.[0]);
  const imageAlt = event.imageCaptions?.[0] ?? uiStrings.events.fallbackImageAlt;
  return (
    <Link className="mobile:w-full" href={getEventUrl(event)}>
      <DashboardResult
        imageUrl={imageUrl}
        imageAlt={imageAlt}
        title={event.title ?? event.id}
        isoDate={event.startDateTime}
        endIsoDate={event.endDateTime}
        subtitle={subtitle}
      />
    </Link>
  );
};
