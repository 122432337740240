'use client';
import {PepIcon} from '#/features/components/PepIcon';
import {Pill} from '#/features/components/Pill';
import {SmallText} from '#/features/components/Text';
import {useUiStrings} from '#/features/store/uiStrings';
import {useTimeframeDateOptions} from '#/utils/dates';
import {DATE_SEARCH_PARAM_KEY} from '#/utils/queryParams';
import Link from 'next/link';

type Props = {
  urlPathBase: string;
  ariaLabel?: string;
};

/**
 * TimeFramePills is a component that displays a list of time frames as pills.
 * Used as the CustomPills prop in HomePreviewSection for the EventsPreview component.
 */
export const TimeFramePills = (props: Props) => {
  const {urlPathBase, ariaLabel} = props;
  const uiStrings = useUiStrings();
  const timeframeDateOptions = useTimeframeDateOptions();
  return (
    <div className="flex flex-wrap gap-2" aria-label={ariaLabel}>
      <Link aria-label={uiStrings.events.allEvents} href={urlPathBase}>
        <Pill
          borderColor={'primary'}
          aria-label={uiStrings.events.allEvents}
          className="stroke-primary hover:border-secondary hover:stroke-secondary"
        >
          <PepIcon iconName="Calendar" strokeColor="inherit" />
          <SmallText>{uiStrings.events.allEvents}</SmallText>
        </Pill>
      </Link>
      {timeframeDateOptions.map(timeFrame => (
        <Link
          key={timeFrame.id}
          aria-label={`${ariaLabel ?? uiStrings.events.timeFrame}: ${timeFrame.title}`}
          href={`${urlPathBase}?${DATE_SEARCH_PARAM_KEY}=${timeFrame.id}`}
        >
          <Pill borderColor={'primary'} className="stroke-primary hover:border-secondary hover:stroke-secondary">
            <PepIcon iconName="Calendar" strokeColor="inherit" />
            <SmallText>{timeFrame.title}</SmallText>
          </Pill>
        </Link>
      ))}
    </div>
  );
};
