import Image from 'next/image';
import {Transition} from '@headlessui/react';
import classNames from 'classnames';
import {useRef, useState, useCallback, useEffect} from 'react';

export type ImageSlide = {
  imageUrl: string;
  title: string;
};

type Props = {
  imageSlides: ImageSlide[];
  animationDuration?: number;
  slideDuration?: number;
  hasProgressDots?: boolean;
  onIndexChange?: (index: number) => void;
  className?: string;
};

export const ImageSlider = (props: Props) => {
  const {
    imageSlides,
    animationDuration = 1000,
    slideDuration = 5000,
    hasProgressDots = true,
    onIndexChange,
    className,
  } = props;

  const frame = useRef<number>(0);
  const firstFrameTime = useRef(performance.now());
  const [currentIndex, setCurrentIndex] = useState(0);
  const total = imageSlides.length;

  const animate = useCallback(
    (now: number) => {
      let timeFraction = (now - firstFrameTime.current) / slideDuration;
      if (timeFraction <= 1) {
        frame.current = requestAnimationFrame(animate);
      } else {
        timeFraction = 1;
        const _currentIndex = (currentIndex + 1) % total;
        setCurrentIndex(_currentIndex);
        onIndexChange?.(_currentIndex);
      }
    },
    [slideDuration, currentIndex, total, onIndexChange]
  );

  useEffect(() => {
    firstFrameTime.current = performance.now();
    frame.current = requestAnimationFrame(animate);
    return () => {
      cancelAnimationFrame(frame.current);
    };
  }, [currentIndex, animate]);

  return (
    <div className={classNames('relative', 'overflow-hidden', 'h-48', className)}>
      <div className="absolute bottom-0 left-0 right-0 top-0">
        {imageSlides?.map((imageSlide, index) => (
          <Transition
            key={index}
            className="absolute bottom-0 left-0 right-0 top-0"
            show={currentIndex === index}
            enter={classNames('transform', 'transition', 'ease-in-out', `duration-${animationDuration}`)}
            enterFrom="translate-x-96 opacity-0"
            enterTo="translate-x-0 opacity-100"
            leave={classNames('transform', 'transition', 'ease-in-out', `duration-${animationDuration}`)}
            leaveFrom="translate-x-0 opacity-100"
            leaveTo="-translate-x-full opacity-0"
          >
            <Image
              key={`header-image-${index}`}
              className="object-cover"
              src={imageSlide.imageUrl}
              alt={imageSlide.title}
              loading="eager"
              fill
            />
          </Transition>
        ))}
      </div>
      {hasProgressDots && (
        <div className="absolute bottom-0 left-0 right-0 top-0 flex items-end justify-center p-3">
          <div className="flex justify-center gap-2">
            {Array.from({length: total}).map((_, index) => {
              const current = index === currentIndex;
              return (
                <div
                  key={index}
                  className={classNames(
                    'z-10 h-2 w-2 rounded-full border border-dark',
                    `${current ? 'bg-primary' : 'border border-midground4 bg-light'}`
                  )}
                />
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};
