'use client';

import {usePathname} from 'next/navigation';
import {type Sight} from '#/cms/schemas/sight.schema';
import {useMemo} from 'react';
import {DashboardResult} from './DashboardResult';
import {usePlaceStore} from '#/features/store/placeStore';
import {useFileUrl} from '#/features/store/clientEnvStore';
import Link from 'next/link';
import {useUiStrings} from '../store/uiStrings';
import {getSeoUrl, getSightUrl} from '#/utils/seo/urls';

type SightResultProps = {
  sight: Sight;
  nested?: boolean;
};

const SightResult = ({sight, nested}: SightResultProps) => {
  const {placeMap} = usePlaceStore();
  const uiStrings = useUiStrings();
  const pathname = usePathname();
  const subtitle = useMemo(
    () =>
      sight.placeIds
        ?.map(placeId => placeMap.get(placeId))
        .filter(Boolean)
        .map(place => place!.title)
        .join(', '),
    [placeMap, sight.placeIds]
  );
  const imageUrl = useFileUrl(sight.imageSources?.[0]);
  return (
    <Link href={nested ? getSeoUrl(pathname, sight) : getSightUrl(sight)}>
      <DashboardResult
        imageUrl={imageUrl}
        imageAlt={sight.imageCaptions?.[0] ?? uiStrings.sights.fallbackImageAlt}
        title={sight.title ?? sight.id}
        subtitle={subtitle}
      />
    </Link>
  );
};

export default SightResult;
