'use client';

import {type ColorName} from '#/cms/schemas/theme-colors.schema';
import {PepIcon, PepIconSpacer} from '#/features/components/PepIcon';
import {Pill} from '#/features/components/Pill';
import {BodyText} from '#/features/components/Text';
import {type PepIconName} from '@calvium/pep-icons';
import classNames from 'classnames';
import Link from 'next/link';
import {usePathname} from 'next/navigation';
import {useCallback} from 'react';
import useAnalytics, {AnalyticsEventAction, AnalyticsEventCategory} from '../analytics/useAnalytics';

// TODO: Why do we have three Button components? ... Button, IconButton, and LinkButton
// They are all very similar so we should pick this one and make it versatile enough to handle all use cases ie - different sizes and rename it to just button

type LinkButtonProps = {
  label: string;
  iconLeftName?: PepIconName;
  iconRightName?: PepIconName;
  iconSize?: string;
  color?: ColorName;
  hoverColor?: ColorName;
  className?: string;
  pillClassName?: string;
  url?: string;
  onClick?: () => void;
  targetBlank?: boolean;
};

type ButtonInnerProps = Pick<
  LinkButtonProps,
  'label' | 'iconSize' | 'iconLeftName' | 'iconRightName' | 'color' | 'className' | 'hoverColor'
>;

const ButtonInner = (props: ButtonInnerProps) => {
  const {label, iconLeftName, iconRightName, iconSize = 'w-5', color = 'primary', className, hoverColor} = props;
  return (
    <Pill
      borderColor={color}
      className={classNames('group flex', hoverColor ? `hover:border-${hoverColor}` : '', className)}
    >
      {!!iconRightName && !iconLeftName && <PepIconSpacer size={iconSize} />}
      {!!iconLeftName && (
        <PepIcon iconName={iconLeftName} size={iconSize} strokeColor={color} strokeColorGroupHover={hoverColor} />
      )}
      <div className="m-auto truncate">
        <BodyText className="px-2">{label}</BodyText>
      </div>
      {!!iconRightName && (
        <PepIcon iconName={iconRightName} size={iconSize} strokeColor={color} strokeColorGroupHover={hoverColor} />
      )}
      {!!iconLeftName && !iconRightName && <PepIconSpacer size={iconSize} />}
    </Pill>
  );
};

export const LinkButton = (props: LinkButtonProps) => {
  const {
    label,
    url,
    onClick,
    iconLeftName,
    iconRightName,
    iconSize,
    color,
    hoverColor,
    className,
    pillClassName,
    targetBlank = false,
  } = props;
  const {registerEvent} = useAnalytics();
  const pathname = usePathname();

  const handleClick = useCallback(() => {
    if (url) {
      registerEvent({
        category: AnalyticsEventCategory.ExternalLink,
        action: AnalyticsEventAction.Click,
        label: url,
        pathname,
      });
    }
    if (onClick) {
      onClick();
    }
  }, [onClick, pathname, registerEvent, url]);

  if (url) {
    return (
      <Link href={url} target={targetBlank ? '_blank' : '_self'} onClick={handleClick} className={className}>
        <ButtonInner
          label={label}
          iconLeftName={iconLeftName}
          iconRightName={iconRightName}
          iconSize={iconSize}
          color={color}
          hoverColor={hoverColor}
          className={pillClassName}
        />
      </Link>
    );
  }

  return (
    <button onClick={handleClick} className={className}>
      <ButtonInner
        label={label}
        iconLeftName={iconLeftName}
        iconRightName={iconRightName}
        iconSize={iconSize}
        color={color}
        hoverColor={hoverColor}
        className={pillClassName}
      />
    </button>
  );
};
