'use client';

import {type CategoryCollectionName} from '#/features/components/Categories';
import {LinkButton} from '#/features/components/LinkButton';
import {ShowOnMapButton} from '#/features/components/ShowOnMapButton';
import {Heading2} from '#/features/components/Text';
import {type PropsWithChildren, type ReactNode} from 'react';

type Props = PropsWithChildren<{
  title: string;
  showAllButtonLabel: string;
  showOnMapButtonLabel: string;
  categoryCollectionName?: CategoryCollectionName;
  categoryIds?: string[];
  urlPathBase: string;
  Pills: ReactNode;
}>;

/**
 * Generic preview section for the home page. Used in SightsPreview, EventsPreview, etc.
 */
export const HomePreviewSection = (props: Props) => {
  const {title, showAllButtonLabel, showOnMapButtonLabel, urlPathBase, children, Pills} = props;
  return (
    <div className="flex flex-col gap-3">
      <Heading2>{title}</Heading2>
      <div className="mb-1 flex items-center justify-between">{!!Pills && Pills}</div>
      <div className="grid gap-4 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4">{children}</div>
      <LinkButton
        url={urlPathBase}
        className="relative my-2 w-full items-center justify-center"
        color="primary"
        hoverColor="secondary"
        label={showAllButtonLabel}
        iconRightName="ArrowRight"
      />

      <ShowOnMapButton label={showOnMapButtonLabel} href={`${urlPathBase}?panelView=map`} />
    </div>
  );
};
