'use client';

import {type Link} from '#/cms/schemas/link.schema';
import {LinkCard} from '#/features/components/LinkCard';
import classNames from 'classnames';

type Props = {
  links: Link[];
};

export const HomeLinks = (props: Props) => {
  const {links} = props;
  if (!links.length) return null;
  const lgGridCols = links.length < 3 ? links.length : 3;
  const smGridCols = links.length < 2 ? links.length : 2;

  return (
    <div
      className={classNames('grid', 'grid-cols-1', 'gap-4', `sm:grid-cols-${smGridCols}`, `lg:grid-cols-${lgGridCols}`)}
    >
      {links.map((link, index) => (
        <div key={`link-${index}`} className="">
          <LinkCard link={link} className="h-full" />
        </div>
      ))}
    </div>
  );
};
