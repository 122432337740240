import type {Event} from '#/cms/schemas/event.schema';
import urls from '#/urls';
import type {Sight} from '#/cms/schemas/sight.schema';

export function getSeoUrl(baseUrl: string, entity: {id: string; slug?: string}) {
  return `${baseUrl}/${entity.slug ?? entity.id}`;
}

export const getEventUrl = (event: Event) => {
  return getSeoUrl(urls.events, event);
};

export const getSightUrl = (sight: Sight) => {
  return getSeoUrl(urls.sights, sight);
};
