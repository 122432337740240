'use client';
import {Heading1BoldFirstWord} from '#/features/components/Text';
import {ImageSlider, type ImageSlide} from '../../features/components/ImageSlider';

type Props = {
  imageSlides: ImageSlide[];
  title?: string;
  subtitle?: string;
  subtitleCharacterLimit?: number;
  animationDuration?: number;
  slideDuration?: number;
};

export const HomeHeader = (props: Props) => {
  const {
    imageSlides,
    title,
    subtitle = '',
    subtitleCharacterLimit = 100,
    animationDuration = 1000,
    slideDuration = 2000,
  } = props;

  const subtitleText =
    subtitle?.length > subtitleCharacterLimit ? subtitle?.substring(0, subtitleCharacterLimit) + '…' : subtitle;
  const subtitleTextArray = subtitleText.split('\n');

  return (
    <div className="pointer-events-none bg-primary">
      <div className="relative h-full w-full overflow-hidden">
        <ImageSlider
          className="h-60 sm:h-56 md:h-52 lg:h-48"
          imageSlides={imageSlides}
          animationDuration={animationDuration}
          slideDuration={slideDuration}
        />
        <div className="absolute bottom-0 left-0 top-0 items-end bg-gradient-to-r from-black p-0">
          <div className="h-full p-10 pr-20">
            <Heading1BoldFirstWord className="mb-5 text-wrap text-white">{title}</Heading1BoldFirstWord>
            {subtitleTextArray.length > 0 &&
              subtitleTextArray.map((text, index) => (
                <p key={`subtitle-${index}`} className="text-wrap text-white">
                  {text}
                </p>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};
