'use client';
import {type ColorName} from '#/cms/schemas/theme-colors.schema';
import {AMENITIES_SEARCH_PARAM_KEY, CATEGORIES_SEARCH_PARAM_KEY} from '#/utils/queryParams';
import Link from 'next/link';
import {useCategoriesStore} from '../store/categoriesStore';
import {PepIcon} from './PepIcon';
import {Pill} from './Pill';
import {SmallText} from './Text';

export type CategoryCollectionName = 'eventCategories' | 'sightCategories' | 'amenityCategories';
type Props = {
  categoryIds: string[] | undefined;
  ariaLabel: string;
  categoryCollectionName: CategoryCollectionName;
  urlPathBase: string;
  border?: ColorName;
  linkingDisabled?: boolean;
};

export const Categories = (props: Props) => {
  const {
    categoryIds,
    categoryCollectionName,
    urlPathBase,
    border = 'midground3',
    linkingDisabled = false,
    ariaLabel,
  } = props;
  const categories = useCategoriesStore();
  if (!categoryIds) return null;
  if (!categoryCollectionName) return null;

  return (
    <div className="flex flex-wrap gap-2" aria-label={ariaLabel}>
      {categoryIds.map(categoryId => {
        const category = categories[categoryCollectionName].get(categoryId);
        if (!category?.title) return null;
        const href = `${urlPathBase}?${categoryCollectionName === 'amenityCategories' ? AMENITIES_SEARCH_PARAM_KEY : CATEGORIES_SEARCH_PARAM_KEY}=${category.id}`;

        if (linkingDisabled)
          return (
            <Pill borderColor={border} aria-label={`${ariaLabel}: ${category.title}`}>
              <PepIcon iconName={category.pepIcon ?? 'Cog'} />
              <SmallText>{category.title}</SmallText>
            </Pill>
          );

        return (
          <Link key={categoryId} aria-label={`${ariaLabel}: ${category.title}`} href={href}>
            <Pill borderColor={border} className="stroke-primary hover:stroke-secondary">
              <PepIcon iconName={category.pepIcon ?? 'Cog'} strokeColor="inherit" />
              <SmallText>{category.title}</SmallText>
            </Pill>
          </Link>
        );
      })}
    </div>
  );
};
